// source: qis/marketdata/v1/marketdata_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var qis_instrument_v1_instrument_pb = require('../../../qis/instrument/v1/instrument_pb.js');
goog.object.extend(proto, qis_instrument_v1_instrument_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var qis_parties_v1_party_pb = require('../../../qis/parties/v1/party_pb.js');
goog.object.extend(proto, qis_parties_v1_party_pb);
var qis_parties_v1_counterparty_pb = require('../../../qis/parties/v1/counterparty_pb.js');
goog.object.extend(proto, qis_parties_v1_counterparty_pb);
var qis_exchange_v1_exchange_pb = require('../../../qis/exchange/v1/exchange_pb.js');
goog.object.extend(proto, qis_exchange_v1_exchange_pb);
goog.exportSymbol('proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse', null, global);
goog.exportSymbol('proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest', null, global);
goog.exportSymbol('proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse', null, global);
goog.exportSymbol('proto.qis.marketdata.v1.InstrumentRequest', null, global);
goog.exportSymbol('proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice', null, global);
goog.exportSymbol('proto.qis.marketdata.v1.InstrumentResponseRealtime', null, global);
goog.exportSymbol('proto.qis.marketdata.v1.InstrumentSnapshotResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.marketdata.v1.InstrumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.marketdata.v1.InstrumentRequest.repeatedFields_, null);
};
goog.inherits(proto.qis.marketdata.v1.InstrumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.marketdata.v1.InstrumentRequest.displayName = 'proto.qis.marketdata.v1.InstrumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.displayName = 'proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.displayName = 'proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.marketdata.v1.InstrumentResponseRealtime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.marketdata.v1.InstrumentResponseRealtime.displayName = 'proto.qis.marketdata.v1.InstrumentResponseRealtime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.marketdata.v1.InstrumentSnapshotResponse.repeatedFields_, null);
};
goog.inherits(proto.qis.marketdata.v1.InstrumentSnapshotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.marketdata.v1.InstrumentSnapshotResponse.displayName = 'proto.qis.marketdata.v1.InstrumentSnapshotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.repeatedFields_, null);
};
goog.inherits(proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.displayName = 'proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.displayName = 'proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.marketdata.v1.InstrumentRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.marketdata.v1.InstrumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.marketdata.v1.InstrumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.marketdata.v1.InstrumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    instrumentList: jspb.Message.toObjectList(msg.getInstrumentList(),
    qis_instrument_v1_instrument_pb.Instrument.toObject, includeInstance),
    asof: (f = msg.getAsof()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.marketdata.v1.InstrumentRequest}
 */
proto.qis.marketdata.v1.InstrumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.marketdata.v1.InstrumentRequest;
  return proto.qis.marketdata.v1.InstrumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.marketdata.v1.InstrumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.marketdata.v1.InstrumentRequest}
 */
proto.qis.marketdata.v1.InstrumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_instrument_v1_instrument_pb.Instrument;
      reader.readMessage(value,qis_instrument_v1_instrument_pb.Instrument.deserializeBinaryFromReader);
      msg.addInstrument(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAsof(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.marketdata.v1.InstrumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.marketdata.v1.InstrumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.marketdata.v1.InstrumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrumentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      qis_instrument_v1_instrument_pb.Instrument.serializeBinaryToWriter
    );
  }
  f = message.getAsof();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated qis.instrument.v1.Instrument instrument = 1;
 * @return {!Array<!proto.qis.instrument.v1.Instrument>}
 */
proto.qis.marketdata.v1.InstrumentRequest.prototype.getInstrumentList = function() {
  return /** @type{!Array<!proto.qis.instrument.v1.Instrument>} */ (
    jspb.Message.getRepeatedWrapperField(this, qis_instrument_v1_instrument_pb.Instrument, 1));
};


/**
 * @param {!Array<!proto.qis.instrument.v1.Instrument>} value
 * @return {!proto.qis.marketdata.v1.InstrumentRequest} returns this
*/
proto.qis.marketdata.v1.InstrumentRequest.prototype.setInstrumentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qis.instrument.v1.Instrument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qis.instrument.v1.Instrument}
 */
proto.qis.marketdata.v1.InstrumentRequest.prototype.addInstrument = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qis.instrument.v1.Instrument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.marketdata.v1.InstrumentRequest} returns this
 */
proto.qis.marketdata.v1.InstrumentRequest.prototype.clearInstrumentList = function() {
  return this.setInstrumentList([]);
};


/**
 * optional google.protobuf.Timestamp asof = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.marketdata.v1.InstrumentRequest.prototype.getAsof = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentRequest} returns this
*/
proto.qis.marketdata.v1.InstrumentRequest.prototype.setAsof = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentRequest} returns this
 */
proto.qis.marketdata.v1.InstrumentRequest.prototype.clearAsof = function() {
  return this.setAsof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentRequest.prototype.hasAsof = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    windowLength: jspb.Message.getFieldWithDefault(msg, 4, 0),
    exchange: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice;
  return proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWindowLength(value);
      break;
    case 5:
      var value = /** @type {!proto.qis.exchange.v1.Exchange} */ (reader.readEnum());
      msg.setExchange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getWindowLength();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getExchange();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} returns this
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} returns this
*/
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} returns this
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} returns this
*/
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} returns this
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 window_length = 4;
 * @return {number}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.getWindowLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} returns this
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.setWindowLength = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional qis.exchange.v1.Exchange exchange = 5;
 * @return {!proto.qis.exchange.v1.Exchange}
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.getExchange = function() {
  return /** @type {!proto.qis.exchange.v1.Exchange} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qis.exchange.v1.Exchange} value
 * @return {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} returns this
 */
proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice.prototype.setExchange = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    instrument: (f = msg.getInstrument()) && qis_instrument_v1_instrument_pb.Instrument.toObject(includeInstance, f),
    party: (f = msg.getParty()) && qis_parties_v1_party_pb.Party.toObject(includeInstance, f),
    counterParty: (f = msg.getCounterParty()) && qis_parties_v1_counterparty_pb.Counterparty.toObject(includeInstance, f),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest;
  return proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_instrument_v1_instrument_pb.Instrument;
      reader.readMessage(value,qis_instrument_v1_instrument_pb.Instrument.deserializeBinaryFromReader);
      msg.setInstrument(value);
      break;
    case 2:
      var value = new qis_parties_v1_party_pb.Party;
      reader.readMessage(value,qis_parties_v1_party_pb.Party.deserializeBinaryFromReader);
      msg.setParty(value);
      break;
    case 3:
      var value = new qis_parties_v1_counterparty_pb.Counterparty;
      reader.readMessage(value,qis_parties_v1_counterparty_pb.Counterparty.deserializeBinaryFromReader);
      msg.setCounterParty(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_instrument_v1_instrument_pb.Instrument.serializeBinaryToWriter
    );
  }
  f = message.getParty();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      qis_parties_v1_party_pb.Party.serializeBinaryToWriter
    );
  }
  f = message.getCounterParty();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      qis_parties_v1_counterparty_pb.Counterparty.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional qis.instrument.v1.Instrument instrument = 1;
 * @return {?proto.qis.instrument.v1.Instrument}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.getInstrument = function() {
  return /** @type{?proto.qis.instrument.v1.Instrument} */ (
    jspb.Message.getWrapperField(this, qis_instrument_v1_instrument_pb.Instrument, 1));
};


/**
 * @param {?proto.qis.instrument.v1.Instrument|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} returns this
*/
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.setInstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} returns this
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.clearInstrument = function() {
  return this.setInstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.hasInstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional qis.parties.v1.Party party = 2;
 * @return {?proto.qis.parties.v1.Party}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.getParty = function() {
  return /** @type{?proto.qis.parties.v1.Party} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_party_pb.Party, 2));
};


/**
 * @param {?proto.qis.parties.v1.Party|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} returns this
*/
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.setParty = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} returns this
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.clearParty = function() {
  return this.setParty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.hasParty = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional qis.parties.v1.Counterparty counter_party = 3;
 * @return {?proto.qis.parties.v1.Counterparty}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.getCounterParty = function() {
  return /** @type{?proto.qis.parties.v1.Counterparty} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_counterparty_pb.Counterparty, 3));
};


/**
 * @param {?proto.qis.parties.v1.Counterparty|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} returns this
*/
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.setCounterParty = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} returns this
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.clearCounterParty = function() {
  return this.setCounterParty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.hasCounterParty = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} returns this
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.marketdata.v1.InstrumentResponseRealtime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.marketdata.v1.InstrumentResponseRealtime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.toObject = function(includeInstance, msg) {
  var f, obj = {
    instrument: (f = msg.getInstrument()) && qis_instrument_v1_instrument_pb.Instrument.toObject(includeInstance, f),
    asof: (f = msg.getAsof()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.marketdata.v1.InstrumentResponseRealtime}
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.marketdata.v1.InstrumentResponseRealtime;
  return proto.qis.marketdata.v1.InstrumentResponseRealtime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.marketdata.v1.InstrumentResponseRealtime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.marketdata.v1.InstrumentResponseRealtime}
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_instrument_v1_instrument_pb.Instrument;
      reader.readMessage(value,qis_instrument_v1_instrument_pb.Instrument.deserializeBinaryFromReader);
      msg.setInstrument(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAsof(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.marketdata.v1.InstrumentResponseRealtime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.marketdata.v1.InstrumentResponseRealtime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_instrument_v1_instrument_pb.Instrument.serializeBinaryToWriter
    );
  }
  f = message.getAsof();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional qis.instrument.v1.Instrument instrument = 1;
 * @return {?proto.qis.instrument.v1.Instrument}
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.getInstrument = function() {
  return /** @type{?proto.qis.instrument.v1.Instrument} */ (
    jspb.Message.getWrapperField(this, qis_instrument_v1_instrument_pb.Instrument, 1));
};


/**
 * @param {?proto.qis.instrument.v1.Instrument|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentResponseRealtime} returns this
*/
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.setInstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentResponseRealtime} returns this
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.clearInstrument = function() {
  return this.setInstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.hasInstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp asof = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.getAsof = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentResponseRealtime} returns this
*/
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.setAsof = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentResponseRealtime} returns this
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.clearAsof = function() {
  return this.setAsof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentResponseRealtime.prototype.hasAsof = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.marketdata.v1.InstrumentSnapshotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.marketdata.v1.InstrumentSnapshotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    instrumentList: jspb.Message.toObjectList(msg.getInstrumentList(),
    qis_instrument_v1_instrument_pb.Instrument.toObject, includeInstance),
    asof: (f = msg.getAsof()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.marketdata.v1.InstrumentSnapshotResponse}
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.marketdata.v1.InstrumentSnapshotResponse;
  return proto.qis.marketdata.v1.InstrumentSnapshotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.marketdata.v1.InstrumentSnapshotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.marketdata.v1.InstrumentSnapshotResponse}
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_instrument_v1_instrument_pb.Instrument;
      reader.readMessage(value,qis_instrument_v1_instrument_pb.Instrument.deserializeBinaryFromReader);
      msg.addInstrument(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAsof(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.marketdata.v1.InstrumentSnapshotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.marketdata.v1.InstrumentSnapshotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrumentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      qis_instrument_v1_instrument_pb.Instrument.serializeBinaryToWriter
    );
  }
  f = message.getAsof();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated qis.instrument.v1.Instrument instrument = 1;
 * @return {!Array<!proto.qis.instrument.v1.Instrument>}
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.getInstrumentList = function() {
  return /** @type{!Array<!proto.qis.instrument.v1.Instrument>} */ (
    jspb.Message.getRepeatedWrapperField(this, qis_instrument_v1_instrument_pb.Instrument, 1));
};


/**
 * @param {!Array<!proto.qis.instrument.v1.Instrument>} value
 * @return {!proto.qis.marketdata.v1.InstrumentSnapshotResponse} returns this
*/
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.setInstrumentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qis.instrument.v1.Instrument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qis.instrument.v1.Instrument}
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.addInstrument = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qis.instrument.v1.Instrument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.marketdata.v1.InstrumentSnapshotResponse} returns this
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.clearInstrumentList = function() {
  return this.setInstrumentList([]);
};


/**
 * optional google.protobuf.Timestamp asof = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.getAsof = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentSnapshotResponse} returns this
*/
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.setAsof = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentSnapshotResponse} returns this
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.clearAsof = function() {
  return this.setAsof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentSnapshotResponse.prototype.hasAsof = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    instrumentPriceList: jspb.Message.toObjectList(msg.getInstrumentPriceList(),
    qis_instrument_v1_instrument_pb.InstrumentPrice.toObject, includeInstance),
    asof: (f = msg.getAsof()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse}
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse;
  return proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse}
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_instrument_v1_instrument_pb.InstrumentPrice;
      reader.readMessage(value,qis_instrument_v1_instrument_pb.InstrumentPrice.deserializeBinaryFromReader);
      msg.addInstrumentPrice(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAsof(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrumentPriceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      qis_instrument_v1_instrument_pb.InstrumentPrice.serializeBinaryToWriter
    );
  }
  f = message.getAsof();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated qis.instrument.v1.InstrumentPrice instrument_price = 1;
 * @return {!Array<!proto.qis.instrument.v1.InstrumentPrice>}
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.getInstrumentPriceList = function() {
  return /** @type{!Array<!proto.qis.instrument.v1.InstrumentPrice>} */ (
    jspb.Message.getRepeatedWrapperField(this, qis_instrument_v1_instrument_pb.InstrumentPrice, 1));
};


/**
 * @param {!Array<!proto.qis.instrument.v1.InstrumentPrice>} value
 * @return {!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse} returns this
*/
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.setInstrumentPriceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qis.instrument.v1.InstrumentPrice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qis.instrument.v1.InstrumentPrice}
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.addInstrumentPrice = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qis.instrument.v1.InstrumentPrice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse} returns this
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.clearInstrumentPriceList = function() {
  return this.setInstrumentPriceList([]);
};


/**
 * optional google.protobuf.Timestamp asof = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.getAsof = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse} returns this
*/
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.setAsof = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse} returns this
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.clearAsof = function() {
  return this.setAsof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.prototype.hasAsof = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    instrument: (f = msg.getInstrument()) && qis_instrument_v1_instrument_pb.Instrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse;
  return proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_instrument_v1_instrument_pb.Instrument;
      reader.readMessage(value,qis_instrument_v1_instrument_pb.Instrument.deserializeBinaryFromReader);
      msg.setInstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_instrument_v1_instrument_pb.Instrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional qis.instrument.v1.Instrument instrument = 1;
 * @return {?proto.qis.instrument.v1.Instrument}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.prototype.getInstrument = function() {
  return /** @type{?proto.qis.instrument.v1.Instrument} */ (
    jspb.Message.getWrapperField(this, qis_instrument_v1_instrument_pb.Instrument, 1));
};


/**
 * @param {?proto.qis.instrument.v1.Instrument|undefined} value
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse} returns this
*/
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.prototype.setInstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse} returns this
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.prototype.clearInstrument = function() {
  return this.setInstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.prototype.hasInstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.qis.marketdata.v1);
