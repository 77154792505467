// source: qis/exchange/v1/exchange.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.qis.exchange.v1.Exchange', null, global);
/**
 * @enum {number}
 */
proto.qis.exchange.v1.Exchange = {
  EXCHANGE_UNSPECIFIED: 0,
  B3: 1,
  NASDAQ: 2,
  NYSE: 3,
  FTX: 4,
  BINANCE: 5
};

goog.object.extend(exports, proto.qis.exchange.v1);
