// source: qis/notification/v1/notification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var qis_instrument_v1_instrument_pb = require('../../../qis/instrument/v1/instrument_pb.js');
goog.object.extend(proto, qis_instrument_v1_instrument_pb);
var qis_pricing_v1_pricing_pb = require('../../../qis/pricing/v1/pricing_pb.js');
goog.object.extend(proto, qis_pricing_v1_pricing_pb);
var qis_rfq_v1_rfq_pb = require('../../../qis/rfq/v1/rfq_pb.js');
goog.object.extend(proto, qis_rfq_v1_rfq_pb);
goog.exportSymbol('proto.qis.notification.v1.AckEvent', null, global);
goog.exportSymbol('proto.qis.notification.v1.InstrumentValuationEvent', null, global);
goog.exportSymbol('proto.qis.notification.v1.Notification', null, global);
goog.exportSymbol('proto.qis.notification.v1.Notification.EventCase', null, global);
goog.exportSymbol('proto.qis.notification.v1.PingPongEvent', null, global);
goog.exportSymbol('proto.qis.notification.v1.RFQCreatedEvent', null, global);
goog.exportSymbol('proto.qis.notification.v1.RFQUpdatedEvent', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.notification.v1.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qis.notification.v1.Notification.oneofGroups_);
};
goog.inherits(proto.qis.notification.v1.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.notification.v1.Notification.displayName = 'proto.qis.notification.v1.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.notification.v1.AckEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.notification.v1.AckEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.notification.v1.AckEvent.displayName = 'proto.qis.notification.v1.AckEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.notification.v1.PingPongEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.notification.v1.PingPongEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.notification.v1.PingPongEvent.displayName = 'proto.qis.notification.v1.PingPongEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.notification.v1.InstrumentValuationEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.notification.v1.InstrumentValuationEvent.repeatedFields_, null);
};
goog.inherits(proto.qis.notification.v1.InstrumentValuationEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.notification.v1.InstrumentValuationEvent.displayName = 'proto.qis.notification.v1.InstrumentValuationEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.notification.v1.RFQCreatedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.notification.v1.RFQCreatedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.notification.v1.RFQCreatedEvent.displayName = 'proto.qis.notification.v1.RFQCreatedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.notification.v1.RFQUpdatedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.notification.v1.RFQUpdatedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.notification.v1.RFQUpdatedEvent.displayName = 'proto.qis.notification.v1.RFQUpdatedEvent';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qis.notification.v1.Notification.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.qis.notification.v1.Notification.EventCase = {
  EVENT_NOT_SET: 0,
  ACK_EVENT: 1,
  INSTRUMENT_VALUATION_EVENT: 2,
  RFQ_CREATED_EVENT: 3,
  PING_PONG_EVENT: 4,
  RFQ_UPDATED_EVENT: 5
};

/**
 * @return {proto.qis.notification.v1.Notification.EventCase}
 */
proto.qis.notification.v1.Notification.prototype.getEventCase = function() {
  return /** @type {proto.qis.notification.v1.Notification.EventCase} */(jspb.Message.computeOneofCase(this, proto.qis.notification.v1.Notification.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.notification.v1.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.notification.v1.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.notification.v1.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    ackEvent: (f = msg.getAckEvent()) && proto.qis.notification.v1.AckEvent.toObject(includeInstance, f),
    instrumentValuationEvent: (f = msg.getInstrumentValuationEvent()) && proto.qis.notification.v1.InstrumentValuationEvent.toObject(includeInstance, f),
    rfqCreatedEvent: (f = msg.getRfqCreatedEvent()) && proto.qis.notification.v1.RFQCreatedEvent.toObject(includeInstance, f),
    pingPongEvent: (f = msg.getPingPongEvent()) && proto.qis.notification.v1.PingPongEvent.toObject(includeInstance, f),
    rfqUpdatedEvent: (f = msg.getRfqUpdatedEvent()) && proto.qis.notification.v1.RFQUpdatedEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.notification.v1.Notification}
 */
proto.qis.notification.v1.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.notification.v1.Notification;
  return proto.qis.notification.v1.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.notification.v1.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.notification.v1.Notification}
 */
proto.qis.notification.v1.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qis.notification.v1.AckEvent;
      reader.readMessage(value,proto.qis.notification.v1.AckEvent.deserializeBinaryFromReader);
      msg.setAckEvent(value);
      break;
    case 2:
      var value = new proto.qis.notification.v1.InstrumentValuationEvent;
      reader.readMessage(value,proto.qis.notification.v1.InstrumentValuationEvent.deserializeBinaryFromReader);
      msg.setInstrumentValuationEvent(value);
      break;
    case 3:
      var value = new proto.qis.notification.v1.RFQCreatedEvent;
      reader.readMessage(value,proto.qis.notification.v1.RFQCreatedEvent.deserializeBinaryFromReader);
      msg.setRfqCreatedEvent(value);
      break;
    case 4:
      var value = new proto.qis.notification.v1.PingPongEvent;
      reader.readMessage(value,proto.qis.notification.v1.PingPongEvent.deserializeBinaryFromReader);
      msg.setPingPongEvent(value);
      break;
    case 5:
      var value = new proto.qis.notification.v1.RFQUpdatedEvent;
      reader.readMessage(value,proto.qis.notification.v1.RFQUpdatedEvent.deserializeBinaryFromReader);
      msg.setRfqUpdatedEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.notification.v1.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.notification.v1.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.notification.v1.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAckEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qis.notification.v1.AckEvent.serializeBinaryToWriter
    );
  }
  f = message.getInstrumentValuationEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qis.notification.v1.InstrumentValuationEvent.serializeBinaryToWriter
    );
  }
  f = message.getRfqCreatedEvent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qis.notification.v1.RFQCreatedEvent.serializeBinaryToWriter
    );
  }
  f = message.getPingPongEvent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qis.notification.v1.PingPongEvent.serializeBinaryToWriter
    );
  }
  f = message.getRfqUpdatedEvent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.qis.notification.v1.RFQUpdatedEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional AckEvent ack_event = 1;
 * @return {?proto.qis.notification.v1.AckEvent}
 */
proto.qis.notification.v1.Notification.prototype.getAckEvent = function() {
  return /** @type{?proto.qis.notification.v1.AckEvent} */ (
    jspb.Message.getWrapperField(this, proto.qis.notification.v1.AckEvent, 1));
};


/**
 * @param {?proto.qis.notification.v1.AckEvent|undefined} value
 * @return {!proto.qis.notification.v1.Notification} returns this
*/
proto.qis.notification.v1.Notification.prototype.setAckEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.qis.notification.v1.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.Notification} returns this
 */
proto.qis.notification.v1.Notification.prototype.clearAckEvent = function() {
  return this.setAckEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.Notification.prototype.hasAckEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InstrumentValuationEvent instrument_valuation_event = 2;
 * @return {?proto.qis.notification.v1.InstrumentValuationEvent}
 */
proto.qis.notification.v1.Notification.prototype.getInstrumentValuationEvent = function() {
  return /** @type{?proto.qis.notification.v1.InstrumentValuationEvent} */ (
    jspb.Message.getWrapperField(this, proto.qis.notification.v1.InstrumentValuationEvent, 2));
};


/**
 * @param {?proto.qis.notification.v1.InstrumentValuationEvent|undefined} value
 * @return {!proto.qis.notification.v1.Notification} returns this
*/
proto.qis.notification.v1.Notification.prototype.setInstrumentValuationEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.qis.notification.v1.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.Notification} returns this
 */
proto.qis.notification.v1.Notification.prototype.clearInstrumentValuationEvent = function() {
  return this.setInstrumentValuationEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.Notification.prototype.hasInstrumentValuationEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RFQCreatedEvent rfq_created_event = 3;
 * @return {?proto.qis.notification.v1.RFQCreatedEvent}
 */
proto.qis.notification.v1.Notification.prototype.getRfqCreatedEvent = function() {
  return /** @type{?proto.qis.notification.v1.RFQCreatedEvent} */ (
    jspb.Message.getWrapperField(this, proto.qis.notification.v1.RFQCreatedEvent, 3));
};


/**
 * @param {?proto.qis.notification.v1.RFQCreatedEvent|undefined} value
 * @return {!proto.qis.notification.v1.Notification} returns this
*/
proto.qis.notification.v1.Notification.prototype.setRfqCreatedEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.qis.notification.v1.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.Notification} returns this
 */
proto.qis.notification.v1.Notification.prototype.clearRfqCreatedEvent = function() {
  return this.setRfqCreatedEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.Notification.prototype.hasRfqCreatedEvent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PingPongEvent ping_pong_event = 4;
 * @return {?proto.qis.notification.v1.PingPongEvent}
 */
proto.qis.notification.v1.Notification.prototype.getPingPongEvent = function() {
  return /** @type{?proto.qis.notification.v1.PingPongEvent} */ (
    jspb.Message.getWrapperField(this, proto.qis.notification.v1.PingPongEvent, 4));
};


/**
 * @param {?proto.qis.notification.v1.PingPongEvent|undefined} value
 * @return {!proto.qis.notification.v1.Notification} returns this
*/
proto.qis.notification.v1.Notification.prototype.setPingPongEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.qis.notification.v1.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.Notification} returns this
 */
proto.qis.notification.v1.Notification.prototype.clearPingPongEvent = function() {
  return this.setPingPongEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.Notification.prototype.hasPingPongEvent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RFQUpdatedEvent rfq_updated_event = 5;
 * @return {?proto.qis.notification.v1.RFQUpdatedEvent}
 */
proto.qis.notification.v1.Notification.prototype.getRfqUpdatedEvent = function() {
  return /** @type{?proto.qis.notification.v1.RFQUpdatedEvent} */ (
    jspb.Message.getWrapperField(this, proto.qis.notification.v1.RFQUpdatedEvent, 5));
};


/**
 * @param {?proto.qis.notification.v1.RFQUpdatedEvent|undefined} value
 * @return {!proto.qis.notification.v1.Notification} returns this
*/
proto.qis.notification.v1.Notification.prototype.setRfqUpdatedEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.qis.notification.v1.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.Notification} returns this
 */
proto.qis.notification.v1.Notification.prototype.clearRfqUpdatedEvent = function() {
  return this.setRfqUpdatedEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.Notification.prototype.hasRfqUpdatedEvent = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.notification.v1.AckEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.notification.v1.AckEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.notification.v1.AckEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.AckEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.notification.v1.AckEvent}
 */
proto.qis.notification.v1.AckEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.notification.v1.AckEvent;
  return proto.qis.notification.v1.AckEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.notification.v1.AckEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.notification.v1.AckEvent}
 */
proto.qis.notification.v1.AckEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.notification.v1.AckEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.notification.v1.AckEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.notification.v1.AckEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.AckEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.notification.v1.AckEvent.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.notification.v1.AckEvent} returns this
*/
proto.qis.notification.v1.AckEvent.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.AckEvent} returns this
 */
proto.qis.notification.v1.AckEvent.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.AckEvent.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.notification.v1.PingPongEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.notification.v1.PingPongEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.notification.v1.PingPongEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.PingPongEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.notification.v1.PingPongEvent}
 */
proto.qis.notification.v1.PingPongEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.notification.v1.PingPongEvent;
  return proto.qis.notification.v1.PingPongEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.notification.v1.PingPongEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.notification.v1.PingPongEvent}
 */
proto.qis.notification.v1.PingPongEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.notification.v1.PingPongEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.notification.v1.PingPongEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.notification.v1.PingPongEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.PingPongEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.notification.v1.PingPongEvent.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.notification.v1.PingPongEvent} returns this
*/
proto.qis.notification.v1.PingPongEvent.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.PingPongEvent} returns this
 */
proto.qis.notification.v1.PingPongEvent.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.PingPongEvent.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.notification.v1.InstrumentValuationEvent.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.notification.v1.InstrumentValuationEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.notification.v1.InstrumentValuationEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.notification.v1.InstrumentValuationEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.InstrumentValuationEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    instrument: (f = msg.getInstrument()) && qis_instrument_v1_instrument_pb.Instrument.toObject(includeInstance, f),
    valuationList: jspb.Message.toObjectList(msg.getValuationList(),
    qis_pricing_v1_pricing_pb.Valuation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.notification.v1.InstrumentValuationEvent}
 */
proto.qis.notification.v1.InstrumentValuationEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.notification.v1.InstrumentValuationEvent;
  return proto.qis.notification.v1.InstrumentValuationEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.notification.v1.InstrumentValuationEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.notification.v1.InstrumentValuationEvent}
 */
proto.qis.notification.v1.InstrumentValuationEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_instrument_v1_instrument_pb.Instrument;
      reader.readMessage(value,qis_instrument_v1_instrument_pb.Instrument.deserializeBinaryFromReader);
      msg.setInstrument(value);
      break;
    case 2:
      var value = new qis_pricing_v1_pricing_pb.Valuation;
      reader.readMessage(value,qis_pricing_v1_pricing_pb.Valuation.deserializeBinaryFromReader);
      msg.addValuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.notification.v1.InstrumentValuationEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.notification.v1.InstrumentValuationEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.notification.v1.InstrumentValuationEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.InstrumentValuationEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_instrument_v1_instrument_pb.Instrument.serializeBinaryToWriter
    );
  }
  f = message.getValuationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      qis_pricing_v1_pricing_pb.Valuation.serializeBinaryToWriter
    );
  }
};


/**
 * optional qis.instrument.v1.Instrument instrument = 1;
 * @return {?proto.qis.instrument.v1.Instrument}
 */
proto.qis.notification.v1.InstrumentValuationEvent.prototype.getInstrument = function() {
  return /** @type{?proto.qis.instrument.v1.Instrument} */ (
    jspb.Message.getWrapperField(this, qis_instrument_v1_instrument_pb.Instrument, 1));
};


/**
 * @param {?proto.qis.instrument.v1.Instrument|undefined} value
 * @return {!proto.qis.notification.v1.InstrumentValuationEvent} returns this
*/
proto.qis.notification.v1.InstrumentValuationEvent.prototype.setInstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.InstrumentValuationEvent} returns this
 */
proto.qis.notification.v1.InstrumentValuationEvent.prototype.clearInstrument = function() {
  return this.setInstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.InstrumentValuationEvent.prototype.hasInstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated qis.pricing.v1.Valuation valuation = 2;
 * @return {!Array<!proto.qis.pricing.v1.Valuation>}
 */
proto.qis.notification.v1.InstrumentValuationEvent.prototype.getValuationList = function() {
  return /** @type{!Array<!proto.qis.pricing.v1.Valuation>} */ (
    jspb.Message.getRepeatedWrapperField(this, qis_pricing_v1_pricing_pb.Valuation, 2));
};


/**
 * @param {!Array<!proto.qis.pricing.v1.Valuation>} value
 * @return {!proto.qis.notification.v1.InstrumentValuationEvent} returns this
*/
proto.qis.notification.v1.InstrumentValuationEvent.prototype.setValuationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.qis.pricing.v1.Valuation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qis.pricing.v1.Valuation}
 */
proto.qis.notification.v1.InstrumentValuationEvent.prototype.addValuation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.qis.pricing.v1.Valuation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.notification.v1.InstrumentValuationEvent} returns this
 */
proto.qis.notification.v1.InstrumentValuationEvent.prototype.clearValuationList = function() {
  return this.setValuationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.notification.v1.RFQCreatedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.notification.v1.RFQCreatedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.notification.v1.RFQCreatedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.RFQCreatedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfq: (f = msg.getRfq()) && qis_rfq_v1_rfq_pb.RFQ.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.notification.v1.RFQCreatedEvent}
 */
proto.qis.notification.v1.RFQCreatedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.notification.v1.RFQCreatedEvent;
  return proto.qis.notification.v1.RFQCreatedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.notification.v1.RFQCreatedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.notification.v1.RFQCreatedEvent}
 */
proto.qis.notification.v1.RFQCreatedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_rfq_v1_rfq_pb.RFQ;
      reader.readMessage(value,qis_rfq_v1_rfq_pb.RFQ.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.notification.v1.RFQCreatedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.notification.v1.RFQCreatedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.notification.v1.RFQCreatedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.RFQCreatedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_rfq_v1_rfq_pb.RFQ.serializeBinaryToWriter
    );
  }
};


/**
 * optional qis.rfq.v1.RFQ rfq = 1;
 * @return {?proto.qis.rfq.v1.RFQ}
 */
proto.qis.notification.v1.RFQCreatedEvent.prototype.getRfq = function() {
  return /** @type{?proto.qis.rfq.v1.RFQ} */ (
    jspb.Message.getWrapperField(this, qis_rfq_v1_rfq_pb.RFQ, 1));
};


/**
 * @param {?proto.qis.rfq.v1.RFQ|undefined} value
 * @return {!proto.qis.notification.v1.RFQCreatedEvent} returns this
*/
proto.qis.notification.v1.RFQCreatedEvent.prototype.setRfq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.RFQCreatedEvent} returns this
 */
proto.qis.notification.v1.RFQCreatedEvent.prototype.clearRfq = function() {
  return this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.RFQCreatedEvent.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.notification.v1.RFQUpdatedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.notification.v1.RFQUpdatedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.notification.v1.RFQUpdatedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.RFQUpdatedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfq: (f = msg.getRfq()) && qis_rfq_v1_rfq_pb.RFQ.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.notification.v1.RFQUpdatedEvent}
 */
proto.qis.notification.v1.RFQUpdatedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.notification.v1.RFQUpdatedEvent;
  return proto.qis.notification.v1.RFQUpdatedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.notification.v1.RFQUpdatedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.notification.v1.RFQUpdatedEvent}
 */
proto.qis.notification.v1.RFQUpdatedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_rfq_v1_rfq_pb.RFQ;
      reader.readMessage(value,qis_rfq_v1_rfq_pb.RFQ.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.notification.v1.RFQUpdatedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.notification.v1.RFQUpdatedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.notification.v1.RFQUpdatedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.notification.v1.RFQUpdatedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_rfq_v1_rfq_pb.RFQ.serializeBinaryToWriter
    );
  }
};


/**
 * optional qis.rfq.v1.RFQ rfq = 1;
 * @return {?proto.qis.rfq.v1.RFQ}
 */
proto.qis.notification.v1.RFQUpdatedEvent.prototype.getRfq = function() {
  return /** @type{?proto.qis.rfq.v1.RFQ} */ (
    jspb.Message.getWrapperField(this, qis_rfq_v1_rfq_pb.RFQ, 1));
};


/**
 * @param {?proto.qis.rfq.v1.RFQ|undefined} value
 * @return {!proto.qis.notification.v1.RFQUpdatedEvent} returns this
*/
proto.qis.notification.v1.RFQUpdatedEvent.prototype.setRfq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.notification.v1.RFQUpdatedEvent} returns this
 */
proto.qis.notification.v1.RFQUpdatedEvent.prototype.clearRfq = function() {
  return this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.notification.v1.RFQUpdatedEvent.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.qis.notification.v1);
