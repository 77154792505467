// source: qis/profile/v1/profile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.qis.profile.v1.GlobalLimitsConfiguration', null, global);
goog.exportSymbol('proto.qis.profile.v1.LimitsConfiguration', null, global);
goog.exportSymbol('proto.qis.profile.v1.ProductSpreadConfiguration', null, global);
goog.exportSymbol('proto.qis.profile.v1.Profile', null, global);
goog.exportSymbol('proto.qis.profile.v1.Profile.ConfigurationInstanceCase', null, global);
goog.exportSymbol('proto.qis.profile.v1.ProfileType', null, global);
goog.exportSymbol('proto.qis.profile.v1.SpreadConfiguration', null, global);
goog.exportSymbol('proto.qis.profile.v1.TickerSpreadConfiguration', null, global);
goog.exportSymbol('proto.qis.profile.v1.TickerSpreads', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.profile.v1.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qis.profile.v1.Profile.oneofGroups_);
};
goog.inherits(proto.qis.profile.v1.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.profile.v1.Profile.displayName = 'proto.qis.profile.v1.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.profile.v1.SpreadConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.profile.v1.SpreadConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.profile.v1.SpreadConfiguration.displayName = 'proto.qis.profile.v1.SpreadConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.profile.v1.ProductSpreadConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.profile.v1.ProductSpreadConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.profile.v1.ProductSpreadConfiguration.displayName = 'proto.qis.profile.v1.ProductSpreadConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.profile.v1.TickerSpreads = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.profile.v1.TickerSpreads.repeatedFields_, null);
};
goog.inherits(proto.qis.profile.v1.TickerSpreads, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.profile.v1.TickerSpreads.displayName = 'proto.qis.profile.v1.TickerSpreads';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.profile.v1.TickerSpreadConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.profile.v1.TickerSpreadConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.profile.v1.TickerSpreadConfiguration.displayName = 'proto.qis.profile.v1.TickerSpreadConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.profile.v1.LimitsConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.profile.v1.LimitsConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.profile.v1.LimitsConfiguration.displayName = 'proto.qis.profile.v1.LimitsConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.profile.v1.GlobalLimitsConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.profile.v1.GlobalLimitsConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.profile.v1.GlobalLimitsConfiguration.displayName = 'proto.qis.profile.v1.GlobalLimitsConfiguration';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qis.profile.v1.Profile.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.qis.profile.v1.Profile.ConfigurationInstanceCase = {
  CONFIGURATION_INSTANCE_NOT_SET: 0,
  SPREAD_CONFIGURATION: 3,
  LIMITS_CONFIGURATION: 4
};

/**
 * @return {proto.qis.profile.v1.Profile.ConfigurationInstanceCase}
 */
proto.qis.profile.v1.Profile.prototype.getConfigurationInstanceCase = function() {
  return /** @type {proto.qis.profile.v1.Profile.ConfigurationInstanceCase} */(jspb.Message.computeOneofCase(this, proto.qis.profile.v1.Profile.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.profile.v1.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.profile.v1.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.profile.v1.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profileType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    spreadConfiguration: (f = msg.getSpreadConfiguration()) && proto.qis.profile.v1.SpreadConfiguration.toObject(includeInstance, f),
    limitsConfiguration: (f = msg.getLimitsConfiguration()) && proto.qis.profile.v1.LimitsConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.profile.v1.Profile}
 */
proto.qis.profile.v1.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.profile.v1.Profile;
  return proto.qis.profile.v1.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.profile.v1.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.profile.v1.Profile}
 */
proto.qis.profile.v1.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.qis.profile.v1.ProfileType} */ (reader.readEnum());
      msg.setProfileType(value);
      break;
    case 3:
      var value = new proto.qis.profile.v1.SpreadConfiguration;
      reader.readMessage(value,proto.qis.profile.v1.SpreadConfiguration.deserializeBinaryFromReader);
      msg.setSpreadConfiguration(value);
      break;
    case 4:
      var value = new proto.qis.profile.v1.LimitsConfiguration;
      reader.readMessage(value,proto.qis.profile.v1.LimitsConfiguration.deserializeBinaryFromReader);
      msg.setLimitsConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.profile.v1.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.profile.v1.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.profile.v1.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfileType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSpreadConfiguration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qis.profile.v1.SpreadConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getLimitsConfiguration();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qis.profile.v1.LimitsConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.profile.v1.Profile.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.profile.v1.Profile} returns this
 */
proto.qis.profile.v1.Profile.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProfileType profile_type = 2;
 * @return {!proto.qis.profile.v1.ProfileType}
 */
proto.qis.profile.v1.Profile.prototype.getProfileType = function() {
  return /** @type {!proto.qis.profile.v1.ProfileType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qis.profile.v1.ProfileType} value
 * @return {!proto.qis.profile.v1.Profile} returns this
 */
proto.qis.profile.v1.Profile.prototype.setProfileType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SpreadConfiguration spread_configuration = 3;
 * @return {?proto.qis.profile.v1.SpreadConfiguration}
 */
proto.qis.profile.v1.Profile.prototype.getSpreadConfiguration = function() {
  return /** @type{?proto.qis.profile.v1.SpreadConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.qis.profile.v1.SpreadConfiguration, 3));
};


/**
 * @param {?proto.qis.profile.v1.SpreadConfiguration|undefined} value
 * @return {!proto.qis.profile.v1.Profile} returns this
*/
proto.qis.profile.v1.Profile.prototype.setSpreadConfiguration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.qis.profile.v1.Profile.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.profile.v1.Profile} returns this
 */
proto.qis.profile.v1.Profile.prototype.clearSpreadConfiguration = function() {
  return this.setSpreadConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.profile.v1.Profile.prototype.hasSpreadConfiguration = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LimitsConfiguration limits_configuration = 4;
 * @return {?proto.qis.profile.v1.LimitsConfiguration}
 */
proto.qis.profile.v1.Profile.prototype.getLimitsConfiguration = function() {
  return /** @type{?proto.qis.profile.v1.LimitsConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.qis.profile.v1.LimitsConfiguration, 4));
};


/**
 * @param {?proto.qis.profile.v1.LimitsConfiguration|undefined} value
 * @return {!proto.qis.profile.v1.Profile} returns this
*/
proto.qis.profile.v1.Profile.prototype.setLimitsConfiguration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.qis.profile.v1.Profile.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.profile.v1.Profile} returns this
 */
proto.qis.profile.v1.Profile.prototype.clearLimitsConfiguration = function() {
  return this.setLimitsConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.profile.v1.Profile.prototype.hasLimitsConfiguration = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.profile.v1.SpreadConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.profile.v1.SpreadConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.profile.v1.SpreadConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.SpreadConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    productSpreadConfigurationMap: (f = msg.getProductSpreadConfigurationMap()) ? f.toObject(includeInstance, proto.qis.profile.v1.ProductSpreadConfiguration.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.profile.v1.SpreadConfiguration}
 */
proto.qis.profile.v1.SpreadConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.profile.v1.SpreadConfiguration;
  return proto.qis.profile.v1.SpreadConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.profile.v1.SpreadConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.profile.v1.SpreadConfiguration}
 */
proto.qis.profile.v1.SpreadConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getProductSpreadConfigurationMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qis.profile.v1.ProductSpreadConfiguration.deserializeBinaryFromReader, "", new proto.qis.profile.v1.ProductSpreadConfiguration());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.profile.v1.SpreadConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.profile.v1.SpreadConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.profile.v1.SpreadConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.SpreadConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductSpreadConfigurationMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qis.profile.v1.ProductSpreadConfiguration.serializeBinaryToWriter);
  }
};


/**
 * map<string, ProductSpreadConfiguration> product_spread_configuration = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qis.profile.v1.ProductSpreadConfiguration>}
 */
proto.qis.profile.v1.SpreadConfiguration.prototype.getProductSpreadConfigurationMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qis.profile.v1.ProductSpreadConfiguration>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.qis.profile.v1.ProductSpreadConfiguration));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qis.profile.v1.SpreadConfiguration} returns this
 */
proto.qis.profile.v1.SpreadConfiguration.prototype.clearProductSpreadConfigurationMap = function() {
  this.getProductSpreadConfigurationMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.profile.v1.ProductSpreadConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.profile.v1.ProductSpreadConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.profile.v1.ProductSpreadConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.ProductSpreadConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    tickerspreadsMap: (f = msg.getTickerspreadsMap()) ? f.toObject(includeInstance, proto.qis.profile.v1.TickerSpreads.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.profile.v1.ProductSpreadConfiguration}
 */
proto.qis.profile.v1.ProductSpreadConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.profile.v1.ProductSpreadConfiguration;
  return proto.qis.profile.v1.ProductSpreadConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.profile.v1.ProductSpreadConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.profile.v1.ProductSpreadConfiguration}
 */
proto.qis.profile.v1.ProductSpreadConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getTickerspreadsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qis.profile.v1.TickerSpreads.deserializeBinaryFromReader, "", new proto.qis.profile.v1.TickerSpreads());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.profile.v1.ProductSpreadConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.profile.v1.ProductSpreadConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.profile.v1.ProductSpreadConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.ProductSpreadConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTickerspreadsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qis.profile.v1.TickerSpreads.serializeBinaryToWriter);
  }
};


/**
 * map<string, TickerSpreads> TickerSpreads = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qis.profile.v1.TickerSpreads>}
 */
proto.qis.profile.v1.ProductSpreadConfiguration.prototype.getTickerspreadsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qis.profile.v1.TickerSpreads>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.qis.profile.v1.TickerSpreads));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qis.profile.v1.ProductSpreadConfiguration} returns this
 */
proto.qis.profile.v1.ProductSpreadConfiguration.prototype.clearTickerspreadsMap = function() {
  this.getTickerspreadsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.profile.v1.TickerSpreads.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.profile.v1.TickerSpreads.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.profile.v1.TickerSpreads.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.profile.v1.TickerSpreads} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.TickerSpreads.toObject = function(includeInstance, msg) {
  var f, obj = {
    tickerSpreadConfigurationList: jspb.Message.toObjectList(msg.getTickerSpreadConfigurationList(),
    proto.qis.profile.v1.TickerSpreadConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.profile.v1.TickerSpreads}
 */
proto.qis.profile.v1.TickerSpreads.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.profile.v1.TickerSpreads;
  return proto.qis.profile.v1.TickerSpreads.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.profile.v1.TickerSpreads} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.profile.v1.TickerSpreads}
 */
proto.qis.profile.v1.TickerSpreads.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qis.profile.v1.TickerSpreadConfiguration;
      reader.readMessage(value,proto.qis.profile.v1.TickerSpreadConfiguration.deserializeBinaryFromReader);
      msg.addTickerSpreadConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.profile.v1.TickerSpreads.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.profile.v1.TickerSpreads.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.profile.v1.TickerSpreads} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.TickerSpreads.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTickerSpreadConfigurationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qis.profile.v1.TickerSpreadConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TickerSpreadConfiguration ticker_spread_configuration = 1;
 * @return {!Array<!proto.qis.profile.v1.TickerSpreadConfiguration>}
 */
proto.qis.profile.v1.TickerSpreads.prototype.getTickerSpreadConfigurationList = function() {
  return /** @type{!Array<!proto.qis.profile.v1.TickerSpreadConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qis.profile.v1.TickerSpreadConfiguration, 1));
};


/**
 * @param {!Array<!proto.qis.profile.v1.TickerSpreadConfiguration>} value
 * @return {!proto.qis.profile.v1.TickerSpreads} returns this
*/
proto.qis.profile.v1.TickerSpreads.prototype.setTickerSpreadConfigurationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qis.profile.v1.TickerSpreadConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qis.profile.v1.TickerSpreadConfiguration}
 */
proto.qis.profile.v1.TickerSpreads.prototype.addTickerSpreadConfiguration = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qis.profile.v1.TickerSpreadConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.profile.v1.TickerSpreads} returns this
 */
proto.qis.profile.v1.TickerSpreads.prototype.clearTickerSpreadConfigurationList = function() {
  return this.setTickerSpreadConfigurationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.profile.v1.TickerSpreadConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.profile.v1.TickerSpreadConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.profile.v1.TickerSpreadConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.TickerSpreadConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    notional: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    spreadValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.profile.v1.TickerSpreadConfiguration}
 */
proto.qis.profile.v1.TickerSpreadConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.profile.v1.TickerSpreadConfiguration;
  return proto.qis.profile.v1.TickerSpreadConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.profile.v1.TickerSpreadConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.profile.v1.TickerSpreadConfiguration}
 */
proto.qis.profile.v1.TickerSpreadConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNotional(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpreadValue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.profile.v1.TickerSpreadConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.profile.v1.TickerSpreadConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.profile.v1.TickerSpreadConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.TickerSpreadConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotional();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSpreadValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional double notional = 1;
 * @return {number}
 */
proto.qis.profile.v1.TickerSpreadConfiguration.prototype.getNotional = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.profile.v1.TickerSpreadConfiguration} returns this
 */
proto.qis.profile.v1.TickerSpreadConfiguration.prototype.setNotional = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double spread_value = 2;
 * @return {number}
 */
proto.qis.profile.v1.TickerSpreadConfiguration.prototype.getSpreadValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.profile.v1.TickerSpreadConfiguration} returns this
 */
proto.qis.profile.v1.TickerSpreadConfiguration.prototype.setSpreadValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool is_default = 3;
 * @return {boolean}
 */
proto.qis.profile.v1.TickerSpreadConfiguration.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qis.profile.v1.TickerSpreadConfiguration} returns this
 */
proto.qis.profile.v1.TickerSpreadConfiguration.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.profile.v1.LimitsConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.profile.v1.LimitsConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.profile.v1.LimitsConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.LimitsConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalLimitsConfiguration: (f = msg.getGlobalLimitsConfiguration()) && proto.qis.profile.v1.GlobalLimitsConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.profile.v1.LimitsConfiguration}
 */
proto.qis.profile.v1.LimitsConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.profile.v1.LimitsConfiguration;
  return proto.qis.profile.v1.LimitsConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.profile.v1.LimitsConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.profile.v1.LimitsConfiguration}
 */
proto.qis.profile.v1.LimitsConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qis.profile.v1.GlobalLimitsConfiguration;
      reader.readMessage(value,proto.qis.profile.v1.GlobalLimitsConfiguration.deserializeBinaryFromReader);
      msg.setGlobalLimitsConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.profile.v1.LimitsConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.profile.v1.LimitsConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.profile.v1.LimitsConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.LimitsConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalLimitsConfiguration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qis.profile.v1.GlobalLimitsConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional GlobalLimitsConfiguration global_limits_configuration = 1;
 * @return {?proto.qis.profile.v1.GlobalLimitsConfiguration}
 */
proto.qis.profile.v1.LimitsConfiguration.prototype.getGlobalLimitsConfiguration = function() {
  return /** @type{?proto.qis.profile.v1.GlobalLimitsConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.qis.profile.v1.GlobalLimitsConfiguration, 1));
};


/**
 * @param {?proto.qis.profile.v1.GlobalLimitsConfiguration|undefined} value
 * @return {!proto.qis.profile.v1.LimitsConfiguration} returns this
*/
proto.qis.profile.v1.LimitsConfiguration.prototype.setGlobalLimitsConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.profile.v1.LimitsConfiguration} returns this
 */
proto.qis.profile.v1.LimitsConfiguration.prototype.clearGlobalLimitsConfiguration = function() {
  return this.setGlobalLimitsConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.profile.v1.LimitsConfiguration.prototype.hasGlobalLimitsConfiguration = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.profile.v1.GlobalLimitsConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.profile.v1.GlobalLimitsConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.profile.v1.GlobalLimitsConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.GlobalLimitsConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalAmountByMonth: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.profile.v1.GlobalLimitsConfiguration}
 */
proto.qis.profile.v1.GlobalLimitsConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.profile.v1.GlobalLimitsConfiguration;
  return proto.qis.profile.v1.GlobalLimitsConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.profile.v1.GlobalLimitsConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.profile.v1.GlobalLimitsConfiguration}
 */
proto.qis.profile.v1.GlobalLimitsConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalAmountByMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.profile.v1.GlobalLimitsConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.profile.v1.GlobalLimitsConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.profile.v1.GlobalLimitsConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.profile.v1.GlobalLimitsConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalAmountByMonth();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double total_amount_by_month = 1;
 * @return {number}
 */
proto.qis.profile.v1.GlobalLimitsConfiguration.prototype.getTotalAmountByMonth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.profile.v1.GlobalLimitsConfiguration} returns this
 */
proto.qis.profile.v1.GlobalLimitsConfiguration.prototype.setTotalAmountByMonth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.qis.profile.v1.ProfileType = {
  UNSPECIFIED_PROFILE: 0,
  SPREAD_PROFILE: 1,
  LIMITS_PROFILE: 2
};

goog.object.extend(exports, proto.qis.profile.v1);
