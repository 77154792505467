// source: qis/quote/v1/quote.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var qis_regional_v1_regional_pb = require('../../../qis/regional/v1/regional_pb.js');
goog.object.extend(proto, qis_regional_v1_regional_pb);
var qis_product_v1_product_pb = require('../../../qis/product/v1/product_pb.js');
goog.object.extend(proto, qis_product_v1_product_pb);
var qis_parties_v1_party_pb = require('../../../qis/parties/v1/party_pb.js');
goog.object.extend(proto, qis_parties_v1_party_pb);
var qis_parties_v1_counterparty_pb = require('../../../qis/parties/v1/counterparty_pb.js');
goog.object.extend(proto, qis_parties_v1_counterparty_pb);
goog.exportSymbol('proto.qis.quote.v1.Quote', null, global);
goog.exportSymbol('proto.qis.quote.v1.QuoteResult', null, global);
goog.exportSymbol('proto.qis.quote.v1.QuoteResult.ResultTypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.quote.v1.Quote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.quote.v1.Quote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.quote.v1.Quote.displayName = 'proto.qis.quote.v1.Quote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.quote.v1.QuoteResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qis.quote.v1.QuoteResult.oneofGroups_);
};
goog.inherits(proto.qis.quote.v1.QuoteResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.quote.v1.QuoteResult.displayName = 'proto.qis.quote.v1.QuoteResult';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.quote.v1.Quote.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.quote.v1.Quote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.quote.v1.Quote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.quote.v1.Quote.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    replyTime: (f = msg.getReplyTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expiryTime: (f = msg.getExpiryTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    neededQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    isIndicative: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    product: (f = msg.getProduct()) && qis_product_v1_product_pb.Product.toObject(includeInstance, f),
    party: (f = msg.getParty()) && qis_parties_v1_party_pb.Party.toObject(includeInstance, f),
    counterparty: (f = msg.getCounterparty()) && qis_parties_v1_counterparty_pb.Counterparty.toObject(includeInstance, f),
    result: (f = msg.getResult()) && proto.qis.quote.v1.QuoteResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.quote.v1.Quote}
 */
proto.qis.quote.v1.Quote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.quote.v1.Quote;
  return proto.qis.quote.v1.Quote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.quote.v1.Quote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.quote.v1.Quote}
 */
proto.qis.quote.v1.Quote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReplyTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiryTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNeededQuantity(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsIndicative(value);
      break;
    case 7:
      var value = new qis_product_v1_product_pb.Product;
      reader.readMessage(value,qis_product_v1_product_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 8:
      var value = new qis_parties_v1_party_pb.Party;
      reader.readMessage(value,qis_parties_v1_party_pb.Party.deserializeBinaryFromReader);
      msg.setParty(value);
      break;
    case 9:
      var value = new qis_parties_v1_counterparty_pb.Counterparty;
      reader.readMessage(value,qis_parties_v1_counterparty_pb.Counterparty.deserializeBinaryFromReader);
      msg.setCounterparty(value);
      break;
    case 10:
      var value = new proto.qis.quote.v1.QuoteResult;
      reader.readMessage(value,proto.qis.quote.v1.QuoteResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.quote.v1.Quote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.quote.v1.Quote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.quote.v1.Quote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.quote.v1.Quote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReplyTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiryTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNeededQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getIsIndicative();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      qis_product_v1_product_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getParty();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      qis_parties_v1_party_pb.Party.serializeBinaryToWriter
    );
  }
  f = message.getCounterparty();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      qis_parties_v1_counterparty_pb.Counterparty.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.qis.quote.v1.QuoteResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.quote.v1.Quote.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.quote.v1.Quote.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.quote.v1.Quote} returns this
*/
proto.qis.quote.v1.Quote.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.quote.v1.Quote.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp reply_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.quote.v1.Quote.prototype.getReplyTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.quote.v1.Quote} returns this
*/
proto.qis.quote.v1.Quote.prototype.setReplyTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.clearReplyTime = function() {
  return this.setReplyTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.quote.v1.Quote.prototype.hasReplyTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp expiry_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.quote.v1.Quote.prototype.getExpiryTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.quote.v1.Quote} returns this
*/
proto.qis.quote.v1.Quote.prototype.setExpiryTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.clearExpiryTime = function() {
  return this.setExpiryTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.quote.v1.Quote.prototype.hasExpiryTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double needed_quantity = 5;
 * @return {number}
 */
proto.qis.quote.v1.Quote.prototype.getNeededQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.setNeededQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool is_indicative = 6;
 * @return {boolean}
 */
proto.qis.quote.v1.Quote.prototype.getIsIndicative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.setIsIndicative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional qis.product.v1.Product product = 7;
 * @return {?proto.qis.product.v1.Product}
 */
proto.qis.quote.v1.Quote.prototype.getProduct = function() {
  return /** @type{?proto.qis.product.v1.Product} */ (
    jspb.Message.getWrapperField(this, qis_product_v1_product_pb.Product, 7));
};


/**
 * @param {?proto.qis.product.v1.Product|undefined} value
 * @return {!proto.qis.quote.v1.Quote} returns this
*/
proto.qis.quote.v1.Quote.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.quote.v1.Quote.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional qis.parties.v1.Party party = 8;
 * @return {?proto.qis.parties.v1.Party}
 */
proto.qis.quote.v1.Quote.prototype.getParty = function() {
  return /** @type{?proto.qis.parties.v1.Party} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_party_pb.Party, 8));
};


/**
 * @param {?proto.qis.parties.v1.Party|undefined} value
 * @return {!proto.qis.quote.v1.Quote} returns this
*/
proto.qis.quote.v1.Quote.prototype.setParty = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.clearParty = function() {
  return this.setParty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.quote.v1.Quote.prototype.hasParty = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional qis.parties.v1.Counterparty counterparty = 9;
 * @return {?proto.qis.parties.v1.Counterparty}
 */
proto.qis.quote.v1.Quote.prototype.getCounterparty = function() {
  return /** @type{?proto.qis.parties.v1.Counterparty} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_counterparty_pb.Counterparty, 9));
};


/**
 * @param {?proto.qis.parties.v1.Counterparty|undefined} value
 * @return {!proto.qis.quote.v1.Quote} returns this
*/
proto.qis.quote.v1.Quote.prototype.setCounterparty = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.clearCounterparty = function() {
  return this.setCounterparty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.quote.v1.Quote.prototype.hasCounterparty = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional QuoteResult result = 10;
 * @return {?proto.qis.quote.v1.QuoteResult}
 */
proto.qis.quote.v1.Quote.prototype.getResult = function() {
  return /** @type{?proto.qis.quote.v1.QuoteResult} */ (
    jspb.Message.getWrapperField(this, proto.qis.quote.v1.QuoteResult, 10));
};


/**
 * @param {?proto.qis.quote.v1.QuoteResult|undefined} value
 * @return {!proto.qis.quote.v1.Quote} returns this
*/
proto.qis.quote.v1.Quote.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.quote.v1.Quote} returns this
 */
proto.qis.quote.v1.Quote.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.quote.v1.Quote.prototype.hasResult = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qis.quote.v1.QuoteResult.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.qis.quote.v1.QuoteResult.ResultTypeCase = {
  RESULT_TYPE_NOT_SET: 0,
  CASH: 1,
  CRYPTO: 2
};

/**
 * @return {proto.qis.quote.v1.QuoteResult.ResultTypeCase}
 */
proto.qis.quote.v1.QuoteResult.prototype.getResultTypeCase = function() {
  return /** @type {proto.qis.quote.v1.QuoteResult.ResultTypeCase} */(jspb.Message.computeOneofCase(this, proto.qis.quote.v1.QuoteResult.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.quote.v1.QuoteResult.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.quote.v1.QuoteResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.quote.v1.QuoteResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.quote.v1.QuoteResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    rebateFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    cash: (f = msg.getCash()) && qis_regional_v1_regional_pb.Money.toObject(includeInstance, f),
    crypto: (f = msg.getCrypto()) && qis_regional_v1_regional_pb.CryptoCurrency.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.quote.v1.QuoteResult}
 */
proto.qis.quote.v1.QuoteResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.quote.v1.QuoteResult;
  return proto.qis.quote.v1.QuoteResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.quote.v1.QuoteResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.quote.v1.QuoteResult}
 */
proto.qis.quote.v1.QuoteResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProcessingFee(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRebateFee(value);
      break;
    case 1:
      var value = new qis_regional_v1_regional_pb.Money;
      reader.readMessage(value,qis_regional_v1_regional_pb.Money.deserializeBinaryFromReader);
      msg.setCash(value);
      break;
    case 2:
      var value = new qis_regional_v1_regional_pb.CryptoCurrency;
      reader.readMessage(value,qis_regional_v1_regional_pb.CryptoCurrency.deserializeBinaryFromReader);
      msg.setCrypto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.quote.v1.QuoteResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.quote.v1.QuoteResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.quote.v1.QuoteResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.quote.v1.QuoteResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingFee();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getRebateFee();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getCash();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_regional_v1_regional_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getCrypto();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      qis_regional_v1_regional_pb.CryptoCurrency.serializeBinaryToWriter
    );
  }
};


/**
 * optional double processing_fee = 10;
 * @return {number}
 */
proto.qis.quote.v1.QuoteResult.prototype.getProcessingFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.quote.v1.QuoteResult} returns this
 */
proto.qis.quote.v1.QuoteResult.prototype.setProcessingFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double rebate_fee = 11;
 * @return {number}
 */
proto.qis.quote.v1.QuoteResult.prototype.getRebateFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.quote.v1.QuoteResult} returns this
 */
proto.qis.quote.v1.QuoteResult.prototype.setRebateFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional qis.regional.v1.Money cash = 1;
 * @return {?proto.qis.regional.v1.Money}
 */
proto.qis.quote.v1.QuoteResult.prototype.getCash = function() {
  return /** @type{?proto.qis.regional.v1.Money} */ (
    jspb.Message.getWrapperField(this, qis_regional_v1_regional_pb.Money, 1));
};


/**
 * @param {?proto.qis.regional.v1.Money|undefined} value
 * @return {!proto.qis.quote.v1.QuoteResult} returns this
*/
proto.qis.quote.v1.QuoteResult.prototype.setCash = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.qis.quote.v1.QuoteResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.quote.v1.QuoteResult} returns this
 */
proto.qis.quote.v1.QuoteResult.prototype.clearCash = function() {
  return this.setCash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.quote.v1.QuoteResult.prototype.hasCash = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional qis.regional.v1.CryptoCurrency crypto = 2;
 * @return {?proto.qis.regional.v1.CryptoCurrency}
 */
proto.qis.quote.v1.QuoteResult.prototype.getCrypto = function() {
  return /** @type{?proto.qis.regional.v1.CryptoCurrency} */ (
    jspb.Message.getWrapperField(this, qis_regional_v1_regional_pb.CryptoCurrency, 2));
};


/**
 * @param {?proto.qis.regional.v1.CryptoCurrency|undefined} value
 * @return {!proto.qis.quote.v1.QuoteResult} returns this
*/
proto.qis.quote.v1.QuoteResult.prototype.setCrypto = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.qis.quote.v1.QuoteResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.quote.v1.QuoteResult} returns this
 */
proto.qis.quote.v1.QuoteResult.prototype.clearCrypto = function() {
  return this.setCrypto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.quote.v1.QuoteResult.prototype.hasCrypto = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.qis.quote.v1);
