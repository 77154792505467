// source: qis/instrument/v1/instrument.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var qis_pricing_v1_pricing_pb = require('../../../qis/pricing/v1/pricing_pb.js');
goog.object.extend(proto, qis_pricing_v1_pricing_pb);
var qis_regional_v1_regional_pb = require('../../../qis/regional/v1/regional_pb.js');
goog.object.extend(proto, qis_regional_v1_regional_pb);
var qis_exchange_v1_exchange_pb = require('../../../qis/exchange/v1/exchange_pb.js');
goog.object.extend(proto, qis_exchange_v1_exchange_pb);
goog.exportSymbol('proto.qis.instrument.v1.EquityIndex', null, global);
goog.exportSymbol('proto.qis.instrument.v1.EquityStock', null, global);
goog.exportSymbol('proto.qis.instrument.v1.FXForwardLeg', null, global);
goog.exportSymbol('proto.qis.instrument.v1.Instrument', null, global);
goog.exportSymbol('proto.qis.instrument.v1.Instrument.InstrumentTypeCase', null, global);
goog.exportSymbol('proto.qis.instrument.v1.InstrumentPrice', null, global);
goog.exportSymbol('proto.qis.instrument.v1.NDFVanilla', null, global);
goog.exportSymbol('proto.qis.instrument.v1.NonFungibleToken', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.instrument.v1.Instrument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.instrument.v1.Instrument.repeatedFields_, proto.qis.instrument.v1.Instrument.oneofGroups_);
};
goog.inherits(proto.qis.instrument.v1.Instrument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.instrument.v1.Instrument.displayName = 'proto.qis.instrument.v1.Instrument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.instrument.v1.InstrumentPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.instrument.v1.InstrumentPrice.repeatedFields_, null);
};
goog.inherits(proto.qis.instrument.v1.InstrumentPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.instrument.v1.InstrumentPrice.displayName = 'proto.qis.instrument.v1.InstrumentPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.instrument.v1.EquityStock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.instrument.v1.EquityStock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.instrument.v1.EquityStock.displayName = 'proto.qis.instrument.v1.EquityStock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.instrument.v1.EquityIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.instrument.v1.EquityIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.instrument.v1.EquityIndex.displayName = 'proto.qis.instrument.v1.EquityIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.instrument.v1.FXForwardLeg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.instrument.v1.FXForwardLeg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.instrument.v1.FXForwardLeg.displayName = 'proto.qis.instrument.v1.FXForwardLeg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.instrument.v1.NDFVanilla = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.instrument.v1.NDFVanilla, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.instrument.v1.NDFVanilla.displayName = 'proto.qis.instrument.v1.NDFVanilla';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.instrument.v1.NonFungibleToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.instrument.v1.NonFungibleToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.instrument.v1.NonFungibleToken.displayName = 'proto.qis.instrument.v1.NonFungibleToken';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.instrument.v1.Instrument.repeatedFields_ = [7];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qis.instrument.v1.Instrument.oneofGroups_ = [[10,11,12,13]];

/**
 * @enum {number}
 */
proto.qis.instrument.v1.Instrument.InstrumentTypeCase = {
  INSTRUMENT_TYPE_NOT_SET: 0,
  EQ_STOCK: 10,
  EQ_INDEX: 11,
  NDF_VANILLA: 12,
  NON_FUNGIBLE_TOKEN: 13
};

/**
 * @return {proto.qis.instrument.v1.Instrument.InstrumentTypeCase}
 */
proto.qis.instrument.v1.Instrument.prototype.getInstrumentTypeCase = function() {
  return /** @type {proto.qis.instrument.v1.Instrument.InstrumentTypeCase} */(jspb.Message.computeOneofCase(this, proto.qis.instrument.v1.Instrument.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.instrument.v1.Instrument.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.instrument.v1.Instrument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.instrument.v1.Instrument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.Instrument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalSymbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    otc: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    asof: (f = msg.getAsof()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    countryCode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    valuationList: jspb.Message.toObjectList(msg.getValuationList(),
    qis_pricing_v1_pricing_pb.Valuation.toObject, includeInstance),
    exchange: jspb.Message.getFieldWithDefault(msg, 8, 0),
    eqStock: (f = msg.getEqStock()) && proto.qis.instrument.v1.EquityStock.toObject(includeInstance, f),
    eqIndex: (f = msg.getEqIndex()) && proto.qis.instrument.v1.EquityIndex.toObject(includeInstance, f),
    ndfVanilla: (f = msg.getNdfVanilla()) && proto.qis.instrument.v1.NDFVanilla.toObject(includeInstance, f),
    nonFungibleToken: (f = msg.getNonFungibleToken()) && proto.qis.instrument.v1.NonFungibleToken.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.instrument.v1.Instrument}
 */
proto.qis.instrument.v1.Instrument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.instrument.v1.Instrument;
  return proto.qis.instrument.v1.Instrument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.instrument.v1.Instrument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.instrument.v1.Instrument}
 */
proto.qis.instrument.v1.Instrument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalSymbol(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOtc(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAsof(value);
      break;
    case 6:
      var value = /** @type {!proto.qis.regional.v1.Country} */ (reader.readEnum());
      msg.setCountryCode(value);
      break;
    case 7:
      var value = new qis_pricing_v1_pricing_pb.Valuation;
      reader.readMessage(value,qis_pricing_v1_pricing_pb.Valuation.deserializeBinaryFromReader);
      msg.addValuation(value);
      break;
    case 8:
      var value = /** @type {!proto.qis.exchange.v1.Exchange} */ (reader.readEnum());
      msg.setExchange(value);
      break;
    case 10:
      var value = new proto.qis.instrument.v1.EquityStock;
      reader.readMessage(value,proto.qis.instrument.v1.EquityStock.deserializeBinaryFromReader);
      msg.setEqStock(value);
      break;
    case 11:
      var value = new proto.qis.instrument.v1.EquityIndex;
      reader.readMessage(value,proto.qis.instrument.v1.EquityIndex.deserializeBinaryFromReader);
      msg.setEqIndex(value);
      break;
    case 12:
      var value = new proto.qis.instrument.v1.NDFVanilla;
      reader.readMessage(value,proto.qis.instrument.v1.NDFVanilla.deserializeBinaryFromReader);
      msg.setNdfVanilla(value);
      break;
    case 13:
      var value = new proto.qis.instrument.v1.NonFungibleToken;
      reader.readMessage(value,proto.qis.instrument.v1.NonFungibleToken.deserializeBinaryFromReader);
      msg.setNonFungibleToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.instrument.v1.Instrument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.instrument.v1.Instrument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.instrument.v1.Instrument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.Instrument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOtc();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAsof();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCountryCode();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getValuationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      qis_pricing_v1_pricing_pb.Valuation.serializeBinaryToWriter
    );
  }
  f = message.getExchange();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getEqStock();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.qis.instrument.v1.EquityStock.serializeBinaryToWriter
    );
  }
  f = message.getEqIndex();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.qis.instrument.v1.EquityIndex.serializeBinaryToWriter
    );
  }
  f = message.getNdfVanilla();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.qis.instrument.v1.NDFVanilla.serializeBinaryToWriter
    );
  }
  f = message.getNonFungibleToken();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.qis.instrument.v1.NonFungibleToken.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.instrument.v1.Instrument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.qis.instrument.v1.Instrument.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string external_symbol = 3;
 * @return {string}
 */
proto.qis.instrument.v1.Instrument.prototype.getExternalSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.setExternalSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool otc = 4;
 * @return {boolean}
 */
proto.qis.instrument.v1.Instrument.prototype.getOtc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.setOtc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp asof = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.instrument.v1.Instrument.prototype.getAsof = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
*/
proto.qis.instrument.v1.Instrument.prototype.setAsof = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.clearAsof = function() {
  return this.setAsof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.Instrument.prototype.hasAsof = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional qis.regional.v1.Country country_code = 6;
 * @return {!proto.qis.regional.v1.Country}
 */
proto.qis.instrument.v1.Instrument.prototype.getCountryCode = function() {
  return /** @type {!proto.qis.regional.v1.Country} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.qis.regional.v1.Country} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated qis.pricing.v1.Valuation valuation = 7;
 * @return {!Array<!proto.qis.pricing.v1.Valuation>}
 */
proto.qis.instrument.v1.Instrument.prototype.getValuationList = function() {
  return /** @type{!Array<!proto.qis.pricing.v1.Valuation>} */ (
    jspb.Message.getRepeatedWrapperField(this, qis_pricing_v1_pricing_pb.Valuation, 7));
};


/**
 * @param {!Array<!proto.qis.pricing.v1.Valuation>} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
*/
proto.qis.instrument.v1.Instrument.prototype.setValuationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.qis.pricing.v1.Valuation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qis.pricing.v1.Valuation}
 */
proto.qis.instrument.v1.Instrument.prototype.addValuation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.qis.pricing.v1.Valuation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.clearValuationList = function() {
  return this.setValuationList([]);
};


/**
 * optional qis.exchange.v1.Exchange exchange = 8;
 * @return {!proto.qis.exchange.v1.Exchange}
 */
proto.qis.instrument.v1.Instrument.prototype.getExchange = function() {
  return /** @type {!proto.qis.exchange.v1.Exchange} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.qis.exchange.v1.Exchange} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.setExchange = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional EquityStock eq_stock = 10;
 * @return {?proto.qis.instrument.v1.EquityStock}
 */
proto.qis.instrument.v1.Instrument.prototype.getEqStock = function() {
  return /** @type{?proto.qis.instrument.v1.EquityStock} */ (
    jspb.Message.getWrapperField(this, proto.qis.instrument.v1.EquityStock, 10));
};


/**
 * @param {?proto.qis.instrument.v1.EquityStock|undefined} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
*/
proto.qis.instrument.v1.Instrument.prototype.setEqStock = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.qis.instrument.v1.Instrument.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.clearEqStock = function() {
  return this.setEqStock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.Instrument.prototype.hasEqStock = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional EquityIndex eq_index = 11;
 * @return {?proto.qis.instrument.v1.EquityIndex}
 */
proto.qis.instrument.v1.Instrument.prototype.getEqIndex = function() {
  return /** @type{?proto.qis.instrument.v1.EquityIndex} */ (
    jspb.Message.getWrapperField(this, proto.qis.instrument.v1.EquityIndex, 11));
};


/**
 * @param {?proto.qis.instrument.v1.EquityIndex|undefined} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
*/
proto.qis.instrument.v1.Instrument.prototype.setEqIndex = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.qis.instrument.v1.Instrument.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.clearEqIndex = function() {
  return this.setEqIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.Instrument.prototype.hasEqIndex = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NDFVanilla ndf_vanilla = 12;
 * @return {?proto.qis.instrument.v1.NDFVanilla}
 */
proto.qis.instrument.v1.Instrument.prototype.getNdfVanilla = function() {
  return /** @type{?proto.qis.instrument.v1.NDFVanilla} */ (
    jspb.Message.getWrapperField(this, proto.qis.instrument.v1.NDFVanilla, 12));
};


/**
 * @param {?proto.qis.instrument.v1.NDFVanilla|undefined} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
*/
proto.qis.instrument.v1.Instrument.prototype.setNdfVanilla = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.qis.instrument.v1.Instrument.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.clearNdfVanilla = function() {
  return this.setNdfVanilla(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.Instrument.prototype.hasNdfVanilla = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NonFungibleToken non_fungible_token = 13;
 * @return {?proto.qis.instrument.v1.NonFungibleToken}
 */
proto.qis.instrument.v1.Instrument.prototype.getNonFungibleToken = function() {
  return /** @type{?proto.qis.instrument.v1.NonFungibleToken} */ (
    jspb.Message.getWrapperField(this, proto.qis.instrument.v1.NonFungibleToken, 13));
};


/**
 * @param {?proto.qis.instrument.v1.NonFungibleToken|undefined} value
 * @return {!proto.qis.instrument.v1.Instrument} returns this
*/
proto.qis.instrument.v1.Instrument.prototype.setNonFungibleToken = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.qis.instrument.v1.Instrument.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.Instrument} returns this
 */
proto.qis.instrument.v1.Instrument.prototype.clearNonFungibleToken = function() {
  return this.setNonFungibleToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.Instrument.prototype.hasNonFungibleToken = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.instrument.v1.InstrumentPrice.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.instrument.v1.InstrumentPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.instrument.v1.InstrumentPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.InstrumentPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    exchange: jspb.Message.getFieldWithDefault(msg, 3, 0),
    valuationList: jspb.Message.toObjectList(msg.getValuationList(),
    qis_pricing_v1_pricing_pb.ValuationSerie.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.instrument.v1.InstrumentPrice}
 */
proto.qis.instrument.v1.InstrumentPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.instrument.v1.InstrumentPrice;
  return proto.qis.instrument.v1.InstrumentPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.instrument.v1.InstrumentPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.instrument.v1.InstrumentPrice}
 */
proto.qis.instrument.v1.InstrumentPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {!proto.qis.exchange.v1.Exchange} */ (reader.readEnum());
      msg.setExchange(value);
      break;
    case 4:
      var value = new qis_pricing_v1_pricing_pb.ValuationSerie;
      reader.readMessage(value,qis_pricing_v1_pricing_pb.ValuationSerie.deserializeBinaryFromReader);
      msg.addValuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.instrument.v1.InstrumentPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.instrument.v1.InstrumentPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.InstrumentPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExchange();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getValuationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      qis_pricing_v1_pricing_pb.ValuationSerie.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.instrument.v1.InstrumentPrice} returns this
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.instrument.v1.InstrumentPrice} returns this
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional qis.exchange.v1.Exchange exchange = 3;
 * @return {!proto.qis.exchange.v1.Exchange}
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.getExchange = function() {
  return /** @type {!proto.qis.exchange.v1.Exchange} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.qis.exchange.v1.Exchange} value
 * @return {!proto.qis.instrument.v1.InstrumentPrice} returns this
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.setExchange = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated qis.pricing.v1.ValuationSerie valuation = 4;
 * @return {!Array<!proto.qis.pricing.v1.ValuationSerie>}
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.getValuationList = function() {
  return /** @type{!Array<!proto.qis.pricing.v1.ValuationSerie>} */ (
    jspb.Message.getRepeatedWrapperField(this, qis_pricing_v1_pricing_pb.ValuationSerie, 4));
};


/**
 * @param {!Array<!proto.qis.pricing.v1.ValuationSerie>} value
 * @return {!proto.qis.instrument.v1.InstrumentPrice} returns this
*/
proto.qis.instrument.v1.InstrumentPrice.prototype.setValuationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.qis.pricing.v1.ValuationSerie=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qis.pricing.v1.ValuationSerie}
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.addValuation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.qis.pricing.v1.ValuationSerie, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.instrument.v1.InstrumentPrice} returns this
 */
proto.qis.instrument.v1.InstrumentPrice.prototype.clearValuationList = function() {
  return this.setValuationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.instrument.v1.EquityStock.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.instrument.v1.EquityStock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.instrument.v1.EquityStock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.EquityStock.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.instrument.v1.EquityStock}
 */
proto.qis.instrument.v1.EquityStock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.instrument.v1.EquityStock;
  return proto.qis.instrument.v1.EquityStock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.instrument.v1.EquityStock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.instrument.v1.EquityStock}
 */
proto.qis.instrument.v1.EquityStock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.instrument.v1.EquityStock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.instrument.v1.EquityStock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.instrument.v1.EquityStock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.EquityStock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.instrument.v1.EquityIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.instrument.v1.EquityIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.instrument.v1.EquityIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.EquityIndex.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.instrument.v1.EquityIndex}
 */
proto.qis.instrument.v1.EquityIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.instrument.v1.EquityIndex;
  return proto.qis.instrument.v1.EquityIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.instrument.v1.EquityIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.instrument.v1.EquityIndex}
 */
proto.qis.instrument.v1.EquityIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.instrument.v1.EquityIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.instrument.v1.EquityIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.instrument.v1.EquityIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.EquityIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.instrument.v1.FXForwardLeg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.instrument.v1.FXForwardLeg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.FXForwardLeg.toObject = function(includeInstance, msg) {
  var f, obj = {
    notional: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentCurrencyCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    settlementLeg: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fixingDate: (f = msg.getFixingDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    maturityDate: (f = msg.getMaturityDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    exchangeRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.instrument.v1.FXForwardLeg}
 */
proto.qis.instrument.v1.FXForwardLeg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.instrument.v1.FXForwardLeg;
  return proto.qis.instrument.v1.FXForwardLeg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.instrument.v1.FXForwardLeg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.instrument.v1.FXForwardLeg}
 */
proto.qis.instrument.v1.FXForwardLeg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNotional(value);
      break;
    case 2:
      var value = /** @type {!proto.qis.regional.v1.MoneyCode} */ (reader.readEnum());
      msg.setCurrencyCode(value);
      break;
    case 3:
      var value = /** @type {!proto.qis.regional.v1.MoneyCode} */ (reader.readEnum());
      msg.setPaymentCurrencyCode(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSettlementLeg(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFixingDate(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaturityDate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExchangeRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.instrument.v1.FXForwardLeg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.instrument.v1.FXForwardLeg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.FXForwardLeg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotional();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPaymentCurrencyCode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSettlementLeg();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFixingDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMaturityDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExchangeRate();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional double notional = 1;
 * @return {number}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.getNotional = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.setNotional = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional qis.regional.v1.MoneyCode currency_code = 2;
 * @return {!proto.qis.regional.v1.MoneyCode}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.getCurrencyCode = function() {
  return /** @type {!proto.qis.regional.v1.MoneyCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qis.regional.v1.MoneyCode} value
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional qis.regional.v1.MoneyCode payment_currency_code = 3;
 * @return {!proto.qis.regional.v1.MoneyCode}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.getPaymentCurrencyCode = function() {
  return /** @type {!proto.qis.regional.v1.MoneyCode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.qis.regional.v1.MoneyCode} value
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.setPaymentCurrencyCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
*/
proto.qis.instrument.v1.FXForwardLeg.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 settlement_leg = 5;
 * @return {number}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.getSettlementLeg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.setSettlementLeg = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp fixing_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.getFixingDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
*/
proto.qis.instrument.v1.FXForwardLeg.prototype.setFixingDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.clearFixingDate = function() {
  return this.setFixingDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.hasFixingDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp maturity_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.getMaturityDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
*/
proto.qis.instrument.v1.FXForwardLeg.prototype.setMaturityDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.clearMaturityDate = function() {
  return this.setMaturityDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.hasMaturityDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double exchange_rate = 8;
 * @return {number}
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.getExchangeRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.instrument.v1.FXForwardLeg} returns this
 */
proto.qis.instrument.v1.FXForwardLeg.prototype.setExchangeRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.instrument.v1.NDFVanilla.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.instrument.v1.NDFVanilla.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.instrument.v1.NDFVanilla} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.NDFVanilla.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiveLeg: (f = msg.getReceiveLeg()) && proto.qis.instrument.v1.FXForwardLeg.toObject(includeInstance, f),
    paymentLeg: (f = msg.getPaymentLeg()) && proto.qis.instrument.v1.FXForwardLeg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.instrument.v1.NDFVanilla}
 */
proto.qis.instrument.v1.NDFVanilla.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.instrument.v1.NDFVanilla;
  return proto.qis.instrument.v1.NDFVanilla.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.instrument.v1.NDFVanilla} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.instrument.v1.NDFVanilla}
 */
proto.qis.instrument.v1.NDFVanilla.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qis.instrument.v1.FXForwardLeg;
      reader.readMessage(value,proto.qis.instrument.v1.FXForwardLeg.deserializeBinaryFromReader);
      msg.setReceiveLeg(value);
      break;
    case 2:
      var value = new proto.qis.instrument.v1.FXForwardLeg;
      reader.readMessage(value,proto.qis.instrument.v1.FXForwardLeg.deserializeBinaryFromReader);
      msg.setPaymentLeg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.instrument.v1.NDFVanilla.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.instrument.v1.NDFVanilla.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.instrument.v1.NDFVanilla} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.NDFVanilla.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiveLeg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qis.instrument.v1.FXForwardLeg.serializeBinaryToWriter
    );
  }
  f = message.getPaymentLeg();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qis.instrument.v1.FXForwardLeg.serializeBinaryToWriter
    );
  }
};


/**
 * optional FXForwardLeg receive_leg = 1;
 * @return {?proto.qis.instrument.v1.FXForwardLeg}
 */
proto.qis.instrument.v1.NDFVanilla.prototype.getReceiveLeg = function() {
  return /** @type{?proto.qis.instrument.v1.FXForwardLeg} */ (
    jspb.Message.getWrapperField(this, proto.qis.instrument.v1.FXForwardLeg, 1));
};


/**
 * @param {?proto.qis.instrument.v1.FXForwardLeg|undefined} value
 * @return {!proto.qis.instrument.v1.NDFVanilla} returns this
*/
proto.qis.instrument.v1.NDFVanilla.prototype.setReceiveLeg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.NDFVanilla} returns this
 */
proto.qis.instrument.v1.NDFVanilla.prototype.clearReceiveLeg = function() {
  return this.setReceiveLeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.NDFVanilla.prototype.hasReceiveLeg = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FXForwardLeg payment_leg = 2;
 * @return {?proto.qis.instrument.v1.FXForwardLeg}
 */
proto.qis.instrument.v1.NDFVanilla.prototype.getPaymentLeg = function() {
  return /** @type{?proto.qis.instrument.v1.FXForwardLeg} */ (
    jspb.Message.getWrapperField(this, proto.qis.instrument.v1.FXForwardLeg, 2));
};


/**
 * @param {?proto.qis.instrument.v1.FXForwardLeg|undefined} value
 * @return {!proto.qis.instrument.v1.NDFVanilla} returns this
*/
proto.qis.instrument.v1.NDFVanilla.prototype.setPaymentLeg = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.instrument.v1.NDFVanilla} returns this
 */
proto.qis.instrument.v1.NDFVanilla.prototype.clearPaymentLeg = function() {
  return this.setPaymentLeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.instrument.v1.NDFVanilla.prototype.hasPaymentLeg = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.instrument.v1.NonFungibleToken.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.instrument.v1.NonFungibleToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.instrument.v1.NonFungibleToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.NonFungibleToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractAddress: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.instrument.v1.NonFungibleToken}
 */
proto.qis.instrument.v1.NonFungibleToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.instrument.v1.NonFungibleToken;
  return proto.qis.instrument.v1.NonFungibleToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.instrument.v1.NonFungibleToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.instrument.v1.NonFungibleToken}
 */
proto.qis.instrument.v1.NonFungibleToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.instrument.v1.NonFungibleToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.instrument.v1.NonFungibleToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.instrument.v1.NonFungibleToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.instrument.v1.NonFungibleToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string contract_address = 1;
 * @return {string}
 */
proto.qis.instrument.v1.NonFungibleToken.prototype.getContractAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.instrument.v1.NonFungibleToken} returns this
 */
proto.qis.instrument.v1.NonFungibleToken.prototype.setContractAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.qis.instrument.v1);
